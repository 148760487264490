import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  interviews: [],
  interviewData: {},
  interviewLevel: 'Beginner',
  interviewLoading: false,
  interviewCategory: '',
  interviewSubCategory: [],
  interviewSubCategoryImage: '',
  interviewErrors: [],
  interviewSuccess: [],
};



const actualCategories = {
  Frontend: "Frontend",
  Backend: "Backend",
  Mobile: "Mobile",
  Programming: "Programming Languages",
  Cloud: "Cloud",

}

export const getUserInterviews = createAsyncThunk('interview/getUserInterviews ', async (data, thunkAPI) => {
  try {
    const { token } = thunkAPI.getState().User;
    const { url } = thunkAPI.getState().Global;
    const response = await axios.get(
      url + 'interview',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      return thunkAPI.fulfillWithValue(response.data);
    }
  } catch (error) {
    console.log(error);
    return thunkAPI.rejectWithValue(error.response.data?.msg || 'Redux interview/getUserInterviews');
  }
});
export const startInterview = createAsyncThunk('interview/startInterview', async (data, thunkAPI) => {
  try {
    const { token } = thunkAPI.getState().User;
    const { url } = thunkAPI.getState().Global;
    const { level, category, subCategory, numberOfQuestions, intervieweeName, techImage } = data;
    const response = await axios.post(
      url + 'interview/startInterview',
      { level, category: actualCategories[category], subCategory, numberOfQuestions, intervieweeName, techImage },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      return thunkAPI.fulfillWithValue(response.data);
    }
  } catch (error) {
    console.log(error);
    return thunkAPI.rejectWithValue(error.response.data?.msg || 'Redux Create Interview Error');
  }
});

export const endInterview = createAsyncThunk('interview/endInterview', async (data, thunkAPI) => {
  try {
    const { token } = thunkAPI.getState().User;
    const { url } = thunkAPI.getState().Global;
    const { interviewId } = data;
    const response = await axios.post(
      url + 'interview/endInterview',
      { interviewId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return thunkAPI.fulfillWithValue(response.data);
  } catch (error) {
    console.log(error);
    return thunkAPI.rejectWithValue('');
  }
});

const interviewSlice = createSlice({
  name: 'interview',
  initialState,
  reducers: {
    resetInterview: (state) => {
      return { ...initialState };
    },
    setInterviewLevel: (state, actions) => {
      state.interviewLevel = actions.payload;
    },
    setInterviewCategory: (state, actions) => {
      state.interviewCategory = actions.payload;
    },
    setInterviewSubCategory: (state, actions) => {
      state.interviewSubCategory = [actions.payload];
    },
    resetInterviewSubCategory: (state, actions) => {
      state.interviewSubCategory = [];
    },
    setInterviewSubCategoryImage: (state, actions) => {
      state.interviewSubCategoryImage = actions.payload;
    },
    cancelInterview: (state, actions) => {
      const { updatedInterview } = actions.payload;
      state.interview = updatedInterview
      state.interviews.push(updatedInterview)
    },
    updateCurrentInterview: (state, actions) => {
      const { interview } = actions.payload;
      const currentInterviewIndex = state.interviews.findIndex((item) => item._id === interview._id);
      state.interviews[currentInterviewIndex] = interview;
    },
    setInterviewData: (state, actions) => {
      state.interviewData = actions.payload
    },
  },
  extraReducers: (builder) => {
    // Get user Interview
    builder.addCase(getUserInterviews.pending, (state, actions) => {
      state.interviewLoading = true;
      state.interviewErrors = [];
    });
    builder.addCase(getUserInterviews.fulfilled, (state, actions) => {
      state.interviews = actions.payload.interviews.reverse();
      state.interviewData = actions.payload.interviewData;
      state.interviewLoading = false;
    });
    builder.addCase(getUserInterviews.rejected, (state, actions) => {
      state.interviewLoading = false;
      state.interviewErrors.push(actions.payload);
    });
    // Start Interview
    builder.addCase(startInterview.pending, (state, actions) => {
      state.interviewLoading = true;
      state.interviewErrors = [];
    });
    builder.addCase(startInterview.fulfilled, (state, actions) => {
      state.interviews.unshift(actions.payload.interview);
      state.interviewData = actions.payload.interviewData;
      state.interviewLoading = false;
      state.interviewSuccess.push('Interview created successfully.');
    });
    builder.addCase(startInterview.rejected, (state, actions) => {
      state.interviewLoading = false;
      state.interviewErrors.push(actions.payload);
    });
    // End Interview
    builder.addCase(endInterview.pending, (state, actions) => {
      state.interviewLoading = true;
      state.interviewErrors = [];
    });
    // Fulfilled
    builder.addCase(endInterview.fulfilled, (state, actions) => {
      state.interviewLoading = false;
      const { interview } = actions.payload;
      const currentInterviewIndex = state.interviews.findIndex((item) => item._id === interview._id);
      state.interviews[currentInterviewIndex] = interview;

      state.interviews.push(interview);
    });
    builder.addCase(endInterview.rejected, (state, actions) => {
      state.interviewLoading = false;
      state.interviewErrors.push(actions.payload);
    });
  },
});

export const {
  resetInterview,
  setInterviewLevel,
  setInterviewCategory,
  setInterviewSubCategory,
  resetInterviewSubCategory,
  setInterviewSubCategoryImage,
  updateCurrentInterview,
  cancelInterview,
  setInterviewData
} = interviewSlice.actions;

export default interviewSlice.reducer;
