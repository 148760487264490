import React from "react";

export const Title = ({ classname }) => {
  return (
    <>
      <h1
        className={` font-bold inline-block text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-blue-500 ${
          classname ? classname : "text-xl sm:text-2xl lg:text-4xl"
        } `}
      >
        InquisAI
      </h1>
    </>
  );
};
