"use client";
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuGroup,
  DropdownMenuContent,
} from "../ui/dropdown-menu";
import { Button } from "../ui";
import Link from "next/link";
import { FaLinkedin } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa";
import { Title } from "../Globals/Globals";

const Footer = () => {
  return (
    <div className="dark:bg-black bg-muted border-t dark:border-white/20 border-black/20 p-5 sm:p-10 flex md:flex-row flex-col justify-around items-center md:items-start w-full dark:text-white text-black">
      <div className="flex flex-col justify-center items-center gap-2">
        <Title />
        <h1 className="text-center text-lg text-gray-600">
          © InquisAI {new Date().getFullYear()}. All rights reserved
        </h1>
      </div>
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-center text-lg sm:text-xl font-bold">
          Made with ❤️ by Sushant and Priyanshu
        </h1>
        <div className="mt-3 flex justify-center items-center gap-4">
          {/* Separate DropdownMenus for LinkedIn and GitHub */}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button>
                <FaLinkedin size={25} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuGroup>
                <Link
                  target="_blank"
                  href="https://www.linkedin.com/in/sushant-dey-947782214/"
                >
                  <DropdownMenuItem>Sushant Dey</DropdownMenuItem>
                </Link>
                <Link
                  target="_blank"
                  href="https://www.linkedin.com/in/priyanshu-rawat-570b7a19b/"
                >
                  <DropdownMenuItem>Priyanshu Rawat</DropdownMenuItem>
                </Link>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button>
                <FaGithub size={25} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuGroup>
                <Link target="_blank" href="https://github.com/Sushantdeygit">
                  <DropdownMenuItem>Sushant Dey</DropdownMenuItem>
                </Link>
                <Link target="_blank" href="https://github.com/priyanshu-code">
                  <DropdownMenuItem>Priyanshu Rawat</DropdownMenuItem>
                </Link>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </div>
  );
};

export default Footer;
